<template>
  <div aria-label="Pagination" class="flex items-center justify-between py-4 px-4">
    <p class="text-sm text-gray-500">
      Página {{ meta.current_page }} de {{ meta.last_page }}
    </p>
    <div class="flex flex-wrap">
      <button
        rel="first"
        type="button"
        title="Primera"
        @click="firstPage"
        :disabled="!links.prev"
        class="inline-flex items-center rounded-full focus:outline-none bg-white"
      >
        <faIcon 
        icon="arrow-circle-left" 
        type="fa" 
        class="text-4xl"
        :class="[links.prev ? activeClaseArrows : inactiveClassArrows]"
        >
        </faIcon>
      </button>

      <button
        rel="prev"
        type="button"
        @click="prevPage"
        :disabled="!links.prev"
        :class="[links.prev ? activeClass : inactiveClass]"
        class="px-3 text-base text-white rounded-sm mr-1"
      >
        Anterior
      </button>

      <button
        rel="next"
        type="button"
        @click="nextPage"
        :disabled="!links.next"
        :class="[links.next ? activeClass : inactiveClass]"
        class="px-3 text-base text-white rounded-sm"
      >
       Siguiente 
      </button>

      <button
        rel="last"
        type="button"
        title="Última"
        @click="lastPage"
        :disabled="!links.next"
        class="rounded-full focus:outline-none items-center inline-flex bg-white"
      >
        <faIcon 
        icon="arrow-circle-right" 
        type="fa" 
        class="text-4xl"
        :class="[links.next ? activeClaseArrows : inactiveClassArrows]"
        >
        </faIcon>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  setup() {
    return {
      activeClass: "bg-blue-500 hover:bg-blue-700",
      inactiveClass: "bg-gray-400",
      inactiveClassArrows: "text-gray-400",
      activeClaseArrows: "text-blue-500 hover:text-blue-700"
    }
  },
  props: {
    action: {
      type: String,
      required: true,
    },
    path: {
      type: String,
      default: null,
    },
    meta: {
      type: Object,
      required: true,
    },
    links: {
      type: Object,
      required: true,
    },
  },
  methods: {
    firstPage() {
      this.$store.dispatch(this.action, this.links.first).then(() => {
        if (this.path) {
          this.$router.replace({
            query: { page: 1 },
          });
        }
      });
    },
    prevPage() {
      this.$store.dispatch(this.action, this.links.prev).then(() => {
        if (this.path) {
          this.$router.replace({
            query: { page: this.meta.current_page },
          });
        }
      });
    },
    nextPage() {
      this.$store.dispatch(this.action, this.links.next).then(() => {
        if (this.path) {
          this.$router.replace({
            query: { page: this.meta.current_page },
          });
        }
      });
    },
    lastPage() {
      this.$store.dispatch(this.action, this.links.last).then(() => {
        if (this.path) {
          this.$router.replace({
            query: { page: this.meta.last_page },
          });
        }
      });
    },
  },
};
</script>
