<template>
  <div class="list-table">
    <div class="sm:-mx-8 sm:px-8 py-4">

      <div class="relative flex flex-col justify-end sm:flex-row sm:pr-6 sm:pt-2 sm:pb-2 bg-white mb-2 rounded-lg shadow">
        <div class="py-4 sm:pr-2 pl-px">
          <p class="pt-2 px-6 text-blue-700 text-sm 2xl:text-lg text-justify">
          <faIcon
            icon="exclamation-circle"
            type="fa"
            class="text-blue-700 text-md"
            >
            </faIcon>
            Puedes exportar los datos de la tabla especificando un nombre y tipo de archivo.
          </p>
        </div>

        <div class="py-4 sm:pr-2 px-4 sm:px-0">
          <input
            placeholder="Nombre de archivo"
            type="text"
            name="filename"
            v-model="excelFileName"
            class="appearance-none border border-gray-500 block pl-1 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 rounded-md form-input"
          >
        </div>

        <div class="py-4 sm:pr-2 px-4 sm:px-0">
          <select
            class="rounded-md border border-gray-500 block pl-1 py-2 h-10 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 form-select"
            name="formato" v-model="excelFormat"
            title="Este es el formato que tendrá el archivo que se creará. XLSX es el formato para Excel.">
            <option value="" selected disabled hidden>Seleccione formato</option>
            <option value="xlsx">XLSX</option>
            <option value="csv">CSV</option>
            <option value="txt">TXT</option>
          </select>
        </div>

        <div class="py-4">
          <button
            @click="exportToExcel"
            class="bg-blue-700 hover:bg-blue-600 rounded-md text-white px-4 py-2"
          >
            Exportar
          </button>
        </div>
      </div>
      <div class="relative">

        <div class="bg-white rounded-lg shadow p-5 overflow-x-auto">
          <table class="text-left mt-3 relative w-full overflow-x-hidden">
            <thead v-if="columnFilters" class="sm:table-header-group block sm:relative border-t-2">
              <tr class="flex flex-col sm:table-row">
                <th
                  class="relative"
                  v-for="(filter, index) in filters"
                  :key="index"
                >
                  <div :class="`py-4 relative ${filter.type == 'number' ? 'w-20' : ''}`">
                    <span v-if="!['none','date-range','number'].includes(filter.type)"
                      class="absolute inset-y-0 left-0 flex items-center pl-1">
                      <faIcon icon="search" type="fas"/>
                    </span>
                    <input v-if="filter.type == 'input' || !filter.type"
                      :placeholder="filter.placeholder"
                      v-model.lazy="filterValues[filter.field]"
                      :ref="filter.field"
                      :class="inputClass"
                      @change="emitActiveFilters({name: filter.field, value: $event.target.value, fieldType: filter.fieldType})"
                      v-debounce="400"
                    />
                    <input v-else-if="filter.type == 'number'"
                      :placeholder="filter.placeholder"
                      v-model.number="filterValues[filter.field]"
                      type="number"
                      :ref="filter.field"
                      :class="numberClass"
                      @change="emitActiveFilters({name: filter.field, value: $event.target.value, fieldType: filter.fieldType})"
                      @focus="moveCleaner($event, filter.field, true)"
                      @blur="moveCleaner($event, filter.field, false)"
                      @mouseover="moveCleaner($event, filter.field, true)"
                      @mouseleave="moveCleaner($event, filter.field, false)"
                      v-debounce="400"
                    />
                    <select v-else-if="filter.type == 'select'"
                      v-model.lazy="filterValues[filter.field]"
                      :ref="filter.field"
                      :class="selectClass"
                      @change="emitActiveFilters({name: filter.field, value: $event.target.value, fieldType: filter.fieldType})"
                    >
                      <option class="text-gray-300" value="" disabled selected hidden> {{filter.placeholder}} </option>
                      <option v-for="(option, index) in filter.options" :key="index" :value="option.value">
                        {{ option.text }}
                      </option>
                    </select>
                    <DateRange v-else-if="filter.type == 'date-range'"
                      @dates-set="$emit('dates-input',$event)"
                      :initialDates="filter.initial"
                    ></DateRange>
                    <span v-if="!['none','date-range'].includes(filter.type)"
                      class="absolute inset-y-0 right-0 flex items-center pr-1"
                      :ref="`${filter.field}-cleaner`"
                    >
                      <faIcon v-if="filter.type == 'select'" icon="sort-down" type="fas" />
                      <button
                        title="Limpiar campo"
                        class="rounded-md relative"
                        @click.stop="($event) => {
                          filterValues[filter.field] = ''
                          $refs[filter.field].value = ''
                          emitActiveFilters({name: filter.field, value: $refs[filter.field].value})
                        }"
                      >
                        <faIcon icon="broom" size="xs" type="fas"/>
                      </button>
                    </span>
                  </div>
                </th>
                <div class="absolute inset-x-0">
                  <span
                    class="absolute inset-x-0 mx-auto text-center bg-gray-100 -top-3 text-xs 2xl-text-base"
                  >
                  Filtros <faIcon icon="filter" type="fas"/>
                  </span>
                </div>
              </tr>
            </thead>
            <thead class="hidden sm:table-header-group sm:relative bg-blue-500 text-white">
              <tr>
                <th
                  v-for="(header, index) in column_titles"
                  :key="index"
                  class="sm:px-3 py-3 border-b-2 border-gray-200 text-left tracking-wider whitespace-no-wrap"
                >
                  <button
                    class="text-xs 2xl:text-sm font-medium uppercase"
                    @click="toggleSort(data_fields[index])"
                  >
                    {{ header }}
                  </button>
                </th>
                <th v-if="managementButtons" class="border-b-2 border-gray-200"></th>
              </tr>
            </thead>
            <tbody class="border-l border-r border-gray-200">
              <tr v-for="(row, index) in filtered_rows"
                :key="index" @click="$emit('row-click')"
                class="sm:hover:bg-blue-200 sm:hover:bg-opacity-25 mb-6 sm:mb-0 flex sm:table-row flex-row flex-wrap sm:flex-no-wrap"
              >
                <td
                  v-for="(field, idx) in data_fields"
                  :key="idx"
                  class="border-b border-gray-200 text-xs 2xl:text-base pl-2 pt-8 sm:px-3 sm:py-5 text-left w-2/4 sm:w-auto relative border-t border-l sm:border-l-0"
                >
                  <span class="font-medium 2xl:text-sm text-gray-800 uppercase sm:hidden absolute top-0 inset-x-0 pl-1 py-1 bg-gray-200 ">
                    {{ columns[field] }}
                  </span>
                  <slot :name="field" :value="value(row, field)">
                    <p class="text-gray-800 break-words text-xs 2xl:text-base font-normal whitespace-no-wrap">
                      {{ value(row, field) }}
                    </p>
                  </slot>
                </td>
                <td v-if="managementButtons"
                  class="border-b border-gray-200 text-xs 2xl:text-sm flex sm:table-cell w-full sm:w-auto py-2"
                >
                  <div class="border border-gray-400 rounded-lg py-1 shadow-md bg-white mr-1">
                    <div v-for="(button, index) in buttons" :key="index"
                    class="text-gray-700 w-full text-xs 2xl:text-sm"
                    >
                      <button v-if="button.condition && condition(button, row)"
                        class="font-semibold text-left hover:bg-blue-500 hover:text-white w-full p-1 pl-3 pr-3 duration-200 focus:outline-none"
                        @click="$emit(button.condition.event, row)"
                        :title="button.condition.text"
                      >
                        {{ button.condition.text}}
                      </button>
                      <button v-else
                        class="font-semibold text-left hover:bg-blue-500 hover:text-white w-full p-1 pl-3 pr-3 duration-200 focus:outline-none"
                        @click="$emit(button.event, row)"
                        :title="button.text"
                      >
                        {{ button.text }}
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DateRange from "@/components/DateRange"

export default {
  name: "ListTable",
  data() {
    return {
      filterValues: {},
      activeFilters: [],
      sortedValue: null,
      excelFileName: "",
      excelFormat: "",
    }
  },
  computed: {
    column_titles() {
      let columns = []
      for (const key in this.columns) {
        if (Object.hasOwnProperty.call(this.columns, key)) {
          columns.push(this.columns[key])
        } else {
          columns.push(key);
        }
      }
      return columns
    },
    data_fields() {
      return Object.keys(this.columns)
    },
    filtered_rows() {
      let filteredRows = this.useStorage ? JSON.parse(JSON.stringify(this.stored_rows))  : this.rows
      
      return filteredRows
    },
    stored_rows() {
      return this.$store.getters[this.rowsGetter]
    },
    data_to_excel() {
      let filteredRows = this.filtered_rows
      filteredRows = filteredRows.map(row => this.data_fields.map( val => {
        return this.value(row,val)
      }))
      return filteredRows
    }
  },
  methods: {
    value(row, field) {
      let value_fields = field.split("%")
      let value = ""
      for (const val of value_fields) {
        if (row[val] != null) {
          value = row[val]

          if (!Object.prototype.hasOwnProperty.call(row,val)  && process.env.NODE_ENV == "development") {
            console.log(field)
            console.log(val)
          }

          row = row[val]
        }
      }
      return value
    },
    toggleSort(value) {
      this.sortedValue = value
    },
    setFilterValues() {
      let filters = []
      for (const filter of JSON.parse(JSON.stringify(this.filters))) {
        filters[filter.field] = filter.initial
      }
      this.filterValues = filters
    },
    sortRows(rows) {
      if (!this.sortedValue) this.sortedValue = this.data_fields[0]
      rows = rows.sort((a, b) => {
        if (typeof this.value(a, this.sortedValue) === "number") {
          return a[this.sortedValue] - b[this.sortedValue]
        } else if (typeof this.value(a, this.sortedValue) === "string") {
          let fa = this.value(a, this.sortedValue).toLowerCase(),
            fb = this.value(b, this.sortedValue).toLowerCase()
          if (fa < fb) {
            return -1
          }
          if (fa > fb) {
            return 1
          }
          return 0
        }
      });
      return rows
    },
    emitActiveFilters(filter) {
      if (!this.activeFilters[filter.name] || this.activeFilters[filter.name].value != filter.value) {
        if (filter.value != "" ) {
          this.activeFilters[filter.name] = {
            value: filter.value,
            fieldType: filter.fieldType || null
          }
        }
        else if (this.activeFilters[filter.name].value && filter.value == "") delete this.activeFilters[filter.name]
        this.$emit('filter-input', this.activeFilters)
      }
    },
    exportToExcel() {
      import('@/utils/export2excel').then((excel) => {
        const tHeader = this.column_titles
        const data = this.data_to_excel

        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: this.excelFileName || 'list',
          autoWidth: true,
          bookType: this.excelFormat || 'xlsx'
        })
      })
    },
    moveCleaner($event, field, entered) {
      let cleaner = this.$refs[field + '-cleaner']
      if($event.type == "focus") {
        cleaner.classList.value += ' focused'
      }else if ($event.type == "blur") {
        cleaner.classList.value = cleaner.classList.value.replace(' focused', '')
      }
      if (entered) {
        cleaner.style.right = "-20px"
        cleaner.style.zIndex = "80"
      }else if(!entered && cleaner.classList.value.includes(" focused") && $event.type == "mouseleave") {
        return
      }else {
        cleaner.style.right ="0"
        cleaner.style.zIndex = "1"
      }
    },
    condition(button, row) {
      let cond = button.condition.value
      let size = cond.length
      let result = false

      eval(`if(${row[cond[0]]}${size > 2 ? cond[1] : '=='}${size > 2 ? cond[2] : cond[1]}){result = true}`)
      return result
    }
  },
  mounted() {
    this.setFilterValues()
  },
  props: {
    filterOnComponent: {
      type: Boolean,
      default: true,
    },
    useStorage: {
      type: Boolean,
      default: false,
    },
    managementButtons: {
      type: Boolean,
      default: false,
    },
    columnFilters: {
      type: Boolean,
      default: false,
    },
    rowsGetter: {
      type: String,
      default: ""
    },
    columns: {
      type: Object,
      required: true,
      default: () => {
        return {};
      },
    },
    filters: {
      type: Array,
      default: () => {
        return []
      }
    },
    rows: {
      type: Array,
      default: () => {
        return [];
      },
    },
    buttons: {
      type: Array,
      default: () => {
        return []
      }
    },
    inputClass: {
      type: String,
      default: "appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-500 border-b block pl-6 py-2 w-full bg-white text-xs 2xl:text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
    },
    numberClass: {
      type: String,
      default: "appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-500 border-b block pl-1 py-2 w-full bg-white text-xs 2xl:text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"

    },
    selectClass: {
      type: String,
      default: "appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-500 border-b block pl-6 pr-3 py-2 h-10 w-full bg-white text-xs 2xl:text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
    }
  },
  components: {
    DateRange
  }
};
</script>